import { Link } from "@remix-run/react";

import { SocialLinks } from "~/ui/frontend/social-links";

type FooterProps = {
  settings: {
    title: string;
    description?: string | null;
    logo?: string | null;
    lightLogo?: string | null;
    facebook?: string | null;
    instagram?: string | null;
  };
};

export const Footer = ({ settings }: FooterProps) => {
  return (
    <footer>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10"></polygon>
        </svg>
      </div>
      <div className="radius-for-skewed bg-white py-20">
        <div className="mx-auto max-w-xs text-center md:max-w-md">
          <Link
            className="mx-auto mb-6 inline-block text-3xl font-bold leading-none"
            to="/"
          >
            <img
              src={settings.logo ?? ""}
              alt="Atelier Egon"
              loading="lazy"
              width="448px"
              height="95px"
            />
          </Link>
          <p className="mb-6 text-sm font-semibold text-gray-600">
            Atelier Egon © {new Date().getFullYear()}. All rights reserved.
          </p>
          <p className="mb-6 text-sm font-semibold text-gray-600">
            🔩 Site web réalisé par{" "}
            <a
              href="https://basaltbytes.com"
              className="font-mono text-red-600"
              rel="nofollow noreferrer"
              target="_blank"
            >
              Basaltbytes.
            </a>
            🪨
          </p>

          <SocialLinks
            className="flex flex-row justify-center gap-2"
            facebook={settings.facebook}
            instagram={settings.instagram}
          />
        </div>
      </div>
      <div className="skew skew-bottom mr-for-radius">
        <svg
          className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10"></polygon>
        </svg>
      </div>
      <div className="skew skew-bottom ml-for-radius">
        <svg
          className="h-8 w-full text-gray-50 md:h-12 lg:h-20"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 10 10"></polygon>
        </svg>
      </div>
    </footer>
  );
};
