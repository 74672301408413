import { useEffect } from "react";
import { useToast } from "@basaltbytes/ui/hooks/use-toast.tsx";
import { ToastAction } from "@basaltbytes/ui/toast.tsx";
import { json } from "@remix-run/node";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  SerializeFrom,
} from "@remix-run/node";
import { Link, Outlet, useLoaderData } from "@remix-run/react";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import type { WebSite } from "schema-dts";

import type { AppHandle } from "~/utils/handle";
import { honeypot } from "~/utils/honeypot.server.ts";
import { mergeMeta } from "~/utils/merge-meta.ts";
import { Footer } from "~/ui/frontend/footer";
import { Navbar } from "~/ui/frontend/navbar";
import { useOptionalUser } from "~/ui/hooks/use-user";
import {
  commitFlashMessageSession,
  getFlashMessageSession,
  type ToastMessage,
} from "~/storage/flash-message.server.ts";
import { auth } from "~/storage/public-auth.server.tsx";
import { sessionStorage } from "~/storage/session.server.ts";
import { organizationRepo, resolve } from "~/container.server";
import { userHasRole } from "~/policies/permissions";
import fontStylesheet from "../../styles/frontend-fonts.css?url";

export const meta: MetaFunction<typeof loader> = mergeMeta(({ data }) => {
  if (!data || !data.settings) {
    return [];
  }
  const settings = data.settings;
  const WebsitechemaLDJSON: WebSite = {
    "@type": "WebSite",
    publisher: {
      "@type": "Organization",
      name: settings.title,
      url: settings.url || "",
      logo: {
        "@type": "ImageObject",
        url: settings.logo || "",
      },
    },
    url: settings.url || "",
    mainEntityOfPage: settings.url || "",
    description: settings.description || "",
  };
  return [
    { title: settings.title },
    {
      name: "description",
      content: settings.metaDescription || settings.description,
    },
    {
      tagName: "link",
      rel: "canonical",
      href: settings.url || "",
    },
    { property: "og:site_name", content: settings.title },
    { property: "og:type", content: "website" },
    { property: "og:title", content: settings.ogTitle || settings.title },
    {
      property: "og:description",
      content: settings.ogDescription || settings.description,
    },
    { property: "og:url", content: settings.url },
    {
      property: "og:image",
      content: settings.ogImage || settings.logo,
    },
    {
      name: "article:publisher",
      content: settings.facebook || settings.twitter,
    },
    { name: "twitter:card", content: "summary_large_image" },
    {
      name: "twitter:title",
      content: settings.twitterTitle || settings.title,
    },
    {
      name: "twitter:description",
      content: settings.twitterDescription || settings.description,
    },
    { name: "twitter:url", content: settings.url },
    {
      name: "twitter:image",
      content: settings.twitterImage || settings.ogImage || settings.logo,
    },
    { name: "twitter:site", content: settings.twitter },
    { property: "og:image:width", content: "486" },
    { property: "og:image:height", content: "269" },
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        ...WebsitechemaLDJSON,
      },
    },
  ];
});

export async function loader({ request }: LoaderFunctionArgs) {
  const { repos } = resolve({
    repos: {
      organization: organizationRepo,
    },
  });
  const [customer, settings, toastSession, session] = await Promise.all([
    auth.isAuthenticated(request),
    repos.organization.getSiteSettings(),
    getFlashMessageSession(request.headers.get("cookie")),
    sessionStorage.getSession(request.headers.get("Cookie")),
  ]);

  const toastMessage = toastSession.get("toastMessage") as ToastMessage;
  const honeyProps = honeypot.getInputProps();

  const headers = new Headers();
  headers.append("Set-Cookie", await sessionStorage.commitSession(session));

  if (!toastMessage) {
    return json(
      {
        settings,
        customer,
        toastMessage: null,
        magicLinkSent: session.has("auth:otp"),
        magicLinkEmail: session.get("auth:email"),
        honeyProps,
      },
      {
        headers,
      },
    );
  }
  headers.append("Set-Cookie", await commitFlashMessageSession(toastSession));
  return json(
    {
      settings,
      customer,
      toastMessage,
      magicLinkSent: session.has("auth:otp"),
      magicLinkEmail: session.get("auth:email"),
      honeyProps,
    },
    {
      headers,
    },
  );
}

export let handle: AppHandle<SerializeFrom<typeof loader>> = {
  headScripts: ({ data }) => {
    return data.settings.plausibleDomain
      ? [
          {
            src: "https://plausible.io/js/plausible.js",
            defer: true,
            dataset: {
              domain: data.settings.plausibleDomain,
            },
          },
        ]
      : [];
  },
  i18n: ["common", "frontend"],
};

export const links: LinksFunction = () => {
  return [
    { rel: "preload", href: fontStylesheet, as: "style" },
    { rel: "stylesheet", href: fontStylesheet },
    {
      rel: "icon",
      href: "/favicon.png",
      type: "image/png",
    },
  ].filter(Boolean);
};

export default function FrontendLayout() {
  const { settings, customer, toastMessage, honeyProps } =
    useLoaderData<typeof loader>();
  const { toast } = useToast();
  const user = useOptionalUser();

  useEffect(() => {
    if (toastMessage?.type === "error") {
      toast({
        variant: "destructive",
        title: toastMessage.title ?? "Uh oh! Something went wrong.",
        description: toastMessage.message,
        action: toastMessage.actionText ? (
          <ToastAction altText={toastMessage.actionText} asChild>
            <Link to={toastMessage.actionUrl || ""}>
              {toastMessage.actionText}
            </Link>
          </ToastAction>
        ) : undefined,
      });
    }
    if (toastMessage?.type === "success") {
      toast({
        title: toastMessage.title ?? "Operation Ok.",
        description: toastMessage.message ?? "Record saved successfully.",
        action: toastMessage.actionText ? (
          <ToastAction altText={toastMessage.actionText} asChild>
            <Link to={toastMessage.actionUrl || ""}>
              {toastMessage.actionText}
            </Link>
          </ToastAction>
        ) : undefined,
      });
    }
  }, [toast, toastMessage]);

  return (
    <HoneypotProvider {...honeyProps}>
      {user && userHasRole(user, "admin") && (
        <div className="fixed bottom-2 right-2 z-50 flex h-12 items-center rounded-md bg-teal-500 px-6 py-4 text-white opacity-50 hover:opacity-100">
          <Link
            className="mr-2 font-bold text-slate-800 underline"
            to={"/admin"}
          >
            Click here{" "}
          </Link>{" "}
          to access the administration panel ⚙️.
        </div>
      )}
      <div className="h-full min-h-full font-frontend">
        <Navbar settings={settings} user={customer} />
        <Outlet />
        <Footer settings={settings} />
      </div>
    </HoneypotProvider>
  );
}
